import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import { COMPANY_ID } from '../../config/api.config';

import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';

import FormatDateTime from "../../shared/components/functional/DateTimeFormatter"
class FinancialTab extends Component {


    state = {
        datalist: [],
        isLoading:true
    };
    componentDidMount() {

        let clientData = { ClientCommunityFinancialDetailId: -1, ClientProgramEnrollmentId: this.props.clientprogramenrollmentid, CompanyId: COMPANY_ID };
        apiService.post('GETCOURTLINKCLIENTCOMMUNITYFINANCIALDETAILS', clientData).then(response => {
            if (response.Success && response.CourtLinkClientCommunityFinancialDetails != null) {
                let details = response.CourtLinkClientCommunityFinancialDetails;
                this.props.onFinancialsDataLoad(details);
                this.setState({
                    datalist: details
                });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }


    render() {

        const { datalist, isLoading } = this.state;
        const currentBalance = datalist.reduce((total, datalist) => total + parseInt(datalist.TransactionAmount, 0), 0);
        const title = "Financial Data (Current Balance = " + new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'USD' }).format(currentBalance) + ")";
        return (
            <MaterialTable
                columns={[
                    {
                        title: "Transaction Date", field: "TransactionDate",
                        render: rowData => <FormatDateTime
                            date={rowData.TransactionDate}
                            format="MM/DD/YY"
                        />

                    },

                    {
                        title: "Transaction Type", field: "TransactionType",
                    },
                    {
                        title: "Amount", field: "TransactionAmount",
                        render: rowData => new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'USD' }).format(rowData.TransactionAmount)

                    },
                    {
                        title: "Comments", field: "Comments"
                    }

                ]}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    toolbar: true,
                    paging: true,
                    paginationType: "normal",
                    headerStyle: {
                        backgroundColor: '#C6C5B9',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight:"325px",
                    columnsButton:true
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: "rows per page"
                    }
                }}
                isLoading = {isLoading}
                data={datalist}
                title={title}
               
            />

        );
    }
}
const mapStateToProps = state => {
    return {
        alert: state.alert
    };
};
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(FinancialTab);

