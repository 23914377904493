import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../../shared/components/ui/form/text-validator';
import SelectValidator from '../../../shared/components/ui/form/select-validator';
import { apiService } from '../../../services/api.service';
import Alert from 'react-bootstrap/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhoneValidator from '../../../shared/components/ui/form/phone-validator';
import * as actions from '../../../store/actions';


class AddUser extends Component {
    constructor(props) {
        super(props);
        let { match } = this.props;
        const userSetId = match.params.id;

        if (userSetId) {
            apiService.post('GETUSERS', { UserId: userSetId }).then(response => {
                if (response.Success && response.CourtLinkUsers) {
                    let userdetails = response.CourtLinkUsers;
                    userdetails[0].Phone = userdetails[0].Phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
                    this.setState({ user: userdetails[0] });
                    //console.log(this.state.user)
                }
            });
        }

        this.state = {
            userId: userSetId,
            role: [],
            user: {
                UserName: '',
                Password: '',
                FirstName: '',
                RoleIds: "",
                Active: 'Y',
                Email: '',
                LastName: '',
                CourtAgency: '',
                Phone: '',
                Address: ''
            },
            message: "",
            messageType: "",
            loading: false
        }
    };

    adduser = (data) => {
        const { userId } = this.state;
        this.setState({ loading: true });
        apiService.post('CREATEUPDATEUSER', data).then(response => {
            if (response.Success) {
                this.setState({ loading: false });
                if(!userId)
                {
                    this.props.actions.showAlert({ message: response.Message, variant: 'success' })
                }
                else
                {
                    this.props.actions.showAlert({ message: response.Message, variant: 'success' })
                } 
                this.props.history.push('/admin/user/users');
            }
            else {
                this.setState({ loading: false, message: response.Message, messageType: "danger" });
            }
        },
            error => this.setState(prevState => {
                this.setState({ loading: false, message: error, messageType: "danger" });
            })
        );
    }
    handleChange = (e) => {
        if (e.target.name === 'RoleIds') {

            let choices = e.target.selectedOptions;
            let final = [];
            for (let i = 0; i < choices.length; i++) {
                final.push(choices[i].value);
            }
            var role = final.toString();
            var user = { ...this.state.user }
            user.RoleIds = role;
            this.setState({ user })
        }
        else if (e.target.name === 'Active') {

            const { user } = this.state;
            user[e.target.name] = e.target.checked ? 'Y' : 'N';
            this.setState({ user });
        }
        else {
            const { user } = this.state;
            user[e.target.name] = e.target.value;
            this.setState({ user });
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();

        const { user, userId } = this.state;
        const { auth } = this.props;
        if (userId) {
            const data = {
                userId: userId,
                UserName: user.UserName,
                FirstName: user.FirstName,
                Roles: user.RoleIds,
                Active: user.Active,
                Email: user.Email, LastName: user.LastName,
                CourtAgency: user.CourtAgency, Address: user.Address,
                Phone: user.Phone,
                ActionPerformedBy: auth.user.UserName
            }
            this.adduser(data)
        }
        else {
            const data = {
                UserName: user.UserName, Password: user.Password,
                FirstName: user.FirstName,
                Roles: user.RoleIds,
                Active: user.Active,
                Email: user.Email, LastName: user.LastName,
                CourtAgency: user.CourtAgency, Address: user.Address,
                Phone: user.Phone,
                ActionPerformedBy: auth.user.UserName,
            }
            this.adduser(data)
        }
    }
    handleCancel = () => {
        this.props.history.push('/admin/user/users');
    }
    getroles = () => {

        apiService.post('GETROLES', { RoleId: -1 }).then(response => {
            this.setState({ role: response.CourtLinkRoles });
        }).catch(response => {
            let errorMessages = response.message;
            alert(errorMessages)
        });
    }
    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.Password) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isPhoneNumberValid', (value) => {
            if (value.trim().length === 12) {
                return true;
            }
            return false;

        });
        this.getroles();
    }
    hadleCloseAlert = (e) => {
        this.setState({ message: "", messageType: "" });
    }

    render() {
        const { user, role, userId, message, messageType, loading } = this.state;
        return (
            <Fragment>
                <section className="innerPage">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="formsDiv">
                                    <h1 className="text-left">{userId ? 'Edit User' : 'Add User'}</h1>
                                    {
                                        message &&
                                        <Alert variant={messageType} onClose={() => this.hadleCloseAlert(false)} dismissible>{message}</Alert>
                                    }
                                    <ValidatorForm
                                        onSubmit={this.handleSubmit}
                                        className="form-horizontal">

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className="control-label">User Name</label>
                                                    <TextValidator
                                                        name="UserName"
                                                        type="text"
                                                        validators={['required']}
                                                        errorMessages={['User Name is Required.']}
                                                        value={user.UserName}
                                                        className="form-control input-sm"
                                                        onChange={this.handleChange}
                                                    />

                                                </div>
                                            </div>

                                            {!userId && <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className="control-label">Password</label>
                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="Password"
                                                        type="password"
                                                        validators={['required', 'minStringLength:6']}
                                                        errorMessages={['Password is Required.', 'Please enter at least 6 characters.']}
                                                        value={user.Password}
                                                        className="form-control input-sm"
                                                    />
                                                </div>
                                            </div>
                                            }
                                            {!userId && <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className="control-label">Confirm Password</label>
                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="ConfirmPassword"
                                                        type="password"
                                                        validators={['required', 'isPasswordMatch']}
                                                        errorMessages={['Confirm Password is Required.', 'Password Mismatch.']}
                                                        value={user.ConfirmPassword}
                                                        className="form-control input-sm"
                                                    />
                                                </div>
                                            </div>
                                            }
                                            <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className=" control-label">First Name </label>
                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="FirstName"
                                                        type="text"
                                                        validators={['required']}
                                                        errorMessages={['First Name is Required.']}
                                                        value={user.FirstName}
                                                        className="form-control input-sm"
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className="control-label">Last Name</label>
                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="LastName"
                                                        type="text"
                                                        validators={['required']}
                                                        errorMessages={['Last Name is Required.']}
                                                        value={user.LastName}
                                                        className="form-control input-sm"
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className="control-label">Court/Agency</label>
                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="CourtAgency"
                                                        type="text"
                                                        validators={['required']}
                                                        errorMessages={['Court/Agency is Required.']}
                                                        value={user.CourtAgency}
                                                        className="form-control input-sm"
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className=" control-label">Email</label>
                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="Email"
                                                        type="text"
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Email is Required.', 'email is not valid']}
                                                        value={user.Email}
                                                        className="form-control input-sm"
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className="control-label">Address</label>

                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="Address"
                                                        type="text"
                                                        validators={['required']}
                                                        errorMessages={['Address is Required.']}
                                                        value={user.Address}
                                                        className="form-control input-sm"
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className="control-label">Phone Number</label>
                                                    {/* <TextValidator
                                                        onChange={this.handleChange}
                                                        name="Phone"
                                                        type="text"
                                                        validators={['required', 'isNumber', 'minStringLength:10']}
                                                        errorMessages={['This field is Required.', 'Please Enter only number', 'Please put 10  digit mobile number']}
                                                        value={user.Phone}
                                                        className="form-control  input-sm"
                                                    /> */}
                                                    {/* <InputMask {...this.props} mask="999-999-9999" maskChar=" " className="form-control  input-sm" /> */}
                                                    <PhoneValidator
                                                        onChange={this.handleChange}
                                                        name="Phone"
                                                        type="text"
                                                        validators={['required', 'isPhoneNumberValid']}
                                                        errorMessages={['Phone number is Required.', 'Please enter a valid Phone number.']}
                                                        value={user.Phone}
                                                        className="form-control  input-sm"
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="form-group1">
                                                    <label className="control-label">Role</label>
                                                    <SelectValidator className="form-control input-sm"
                                                        validators={['required']}
                                                        errorMessages={['Role is Required.']}
                                                        name="RoleIds" value={user.RoleIds.split(',')} onChange={this.handleChange}
                                                        size="4"
                                                        multiple={true}
                                                    >
                                                        {role.map(role => {
                                                            return (
                                                                <option key={role.RoleId} value={role.RoleId}>
                                                                    {role.Role}
                                                                </option>
                                                            );
                                                        })}

                                                    </SelectValidator>
                                                </div>
                                            </div>

                                            <div className="col-sm-12">
                                                <div className="form-group1"><div className="Form">
                                                    <label className="control-label checkbox">
                                                        <input type="checkbox"
                                                            name="Active" checked={user.Active === "Y" ? true : false}
                                                            onChange={this.handleChange}
                                                        /><span className="primary"></span>
                                                    </label>
                                                    <span className="checkActive"> Active</span>
                                                </div>
                                                </div></div>
                                            <div className="col-sm-12">
                                                <div className="form-group1">
                                                    <button className="btn btn-primary" disabled={loading} >{userId ? 'Update' : 'Register'}</button>
                                                    <button className="btn btn-primary" type="button" onClick={this.handleCancel}>Cancel</button>
                                                </div>
                                            </div>

                                        </div>
                                    </ValidatorForm>
                                    {loading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        alert: state.alert
    }
}
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch
            )
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);