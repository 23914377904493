import React, { Fragment, useState } from 'react';
import { NavLink, Link } from "react-router-dom";

import logoImage from '../../../assets/images/logo.png';


const AppHeader = ({ auth, onLogout, showMenu, onToggleMenu }) => {

    const [activeMenu, setActiveMenu] = useState({});

    const toggle = (menuName) => {

        if (activeMenu[menuName]) {
            setActiveMenu({})
        } else {
            setActiveMenu({ [menuName]: true })
        }

    };

    function checker_roles() {
        if (auth.user.Roles && auth.user.Roles.indexOf("Admin") !== -1) {
            return true;
        } else {
            return false;
        }
    }

    const adminlogin = auth.loggedIn && checker_roles();
    return (
        <Fragment>
            <button type="button" className="mobile-nav-toggle d-lg-none"
                onClick={onToggleMenu}
            ><i className={showMenu ? "fa fa-times" : "fa fa-bars"}></i></button>
            <header id="header" className="fixed-top">
                <div className="container-fluid">
                    <div className="logo float-left">
                        <h1><NavLink className="nav-link" to={`/`}><img src={logoImage} alt="logo" /></NavLink></h1>
                    </div>

                    <nav className={showMenu ? 'mobile-nav d-lg-none ' : 'main-nav float-right d-none d-lg-block'}>
                        <ul>
                            <li>
                                <a href="/#home">
                                    Home
                            </a>
                            </li>
                            {
                                auth.loggedIn &&
                                (
                                    <li className="drop-down" onClick={() => toggle("clientData")}>
                                        <a href="/" className="not-active">Client Data</a>

                                        <ul style={{ display: showMenu ? activeMenu.clientData && showMenu ? "block" : " none" : 'block' }}>
                                            <li>
                                                <NavLink className="nav-link" to={`/clientdata/referralcount`}>
                                                    Referral Counts by Program
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="nav-link" to={`/clientdata/referrallist/-1`}>
                                                    Referral List
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                )
                            }
                            {
                                auth.loggedIn && !adminlogin &&
                                (
                                    <li>
                                        <NavLink className="nav-link" to={`/documents/shareddocuments`}>
                                            Documents
                                        </NavLink>
                                    </li>                                
                                )
                            }
                            {
                                adminlogin &&
                                (

                                    <li className="drop-down" onClick={() => toggle("admin")} ><a href="/" className="not-active"
                                    >Admin</a>
                                        <ul style={{ display: showMenu ? activeMenu.admin ? "block" : " none" : 'block' }}>
                                            <li>
                                                <NavLink className="nav-link" to={`/admin/configuration`}>
                                                    Configuration
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="nav-link" to={`/admin/documents`}>
                                                    Documents
                                            </NavLink>
                                            </li>
                                            <li>
                                                <NavLink className="nav-link" to={`/admin/user/users`}>
                                                    User Setup
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                )
                            }
                            <li>
                                <a href="/#contact">
                                    Contact Us
                                </a>
                            </li>
                            {
                                auth.loggedIn && (
                                    <Fragment>
                                        <li className="drop-down" onClick={() => toggle("userinfo")} style={{ marginRight:  showMenu ?  0 : 80 }}><a href="/" className="not-active"><i className="fa fa-user-circle"></i> {auth.user.UserName}</a>
                                            <ul style={{ display: showMenu ? activeMenu.userinfo ? "block" : " none" :'block'}}>
                                                <li><Link to="/changepassword">Change Password</Link></li>
                                                <li><a onClick={onLogout}>Log Out</a></li>
                                            </ul>
                                        </li>
                                    </Fragment>
                                )
                            }
                        </ul>
                    </nav>
                    <div className="mobile-nav-overly" style={{ display: showMenu ? " block" : " none" }}></div>
                </div>
            </header>
        </Fragment>
    );
}

export default AppHeader;