
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ProgramConfig from '../configuration/programconfiguration';
import ServiceConfig from '../configuration/serviceconfiguration';

import * as actions from '../../../store/actions';
import { bindActionCreators } from 'redux';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



class ConfigurationTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            classes: makeStyles(theme => ({
                root: {
                    flexGrow: 1,
                    backgroundColor: theme.palette.background.paper,
                },
            }))
        }
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    };

    handleShowAlert = (data) => {
        this.props.actions.showAlert(data)
    }

    render() {
        const { value, classes } = this.state;
        return (
            <section id="configurations" className="innerPage">
                <div className="container-fluid">
                    <div className={classes.root}>
                        <AppBar position="static">
                            <Tabs value={value} onChange={this.handleChange} >
                                <Tab label="Program" {...a11yProps(0)} />
                                <Tab label="Service" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <ProgramConfig onShowAlert={this.handleShowAlert} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ServiceConfig onShowAlert={this.handleShowAlert} />
                        </TabPanel>

                    </div>
                </div>

            </section >
        )
    }
}


const mapStateToProps = state => {
    return {
        alert: state.alert

    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationTabs);




