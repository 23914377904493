import React, { Component } from 'react';

import { history } from './helpers';

import AppLayout from './shared/layouts/AppLayout';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_TRACKINGID } from './config/api.config'
ReactGA.initialize(GOOGLE_ANALYTICS_TRACKINGID); // Initialize google analytics page view tracking
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
  ReactGA.set({ page: window.location.pathname + window.location.search });
});
class App extends Component {

  componentDidMount() {
    history.listen((location, action) => {
      this.setState({ showMenu: false });
    });
  }


  render() {
    return (
      <AppLayout />
    );
  }
}



export default App;
