import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../shared/components/ui/form/text-validator';
import { apiService, localStorageService } from '../../services';
import Alert from 'react-bootstrap/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from '../../store/actions';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        const { auth } = this.props;
        this.state = {
            changepasswordform: {
                userName: auth.user.UserName,
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
                userId: auth.user.UserId,
                message: "",
                messageType: "",
                loading: false
            }
        }
    }
    handleChange = (e) => {
        const { changepasswordform } = this.state;
        changepasswordform[e.target.name] = e.target.value;
        this.setState({ changepasswordform });
    }
    handleSubmit = () => {

        const { changepasswordform } = this.state;
        this.setState({ changepasswordform: { ...this.state.changepasswordform, loading: true } });
        const data = {
            OldPassword: changepasswordform.oldPassword,
            NewPassword: changepasswordform.newPassword,
            ActionPerformedBy: changepasswordform.userName,
            UserId: changepasswordform.userId
        }
        apiService.post('CHANGEPASSWORD', data)
            .then(response => {

                if (response.Success) {
                    localStorageService.storeAuthToken(response.Token);
                    this.setState({ changepasswordform: { ...this.state.changepasswordform, loading: false, message: response.Message, oldPassword: "", newPassword: "", confirmNewPassword: "", messageType: "success" } });
                }
                else {
                    this.setState({ changepasswordform: { ...this.state.changepasswordform, loading: false, message: response.Message, messageType: "danger" } });
                }
            },
                error => this.setState(prevState => {
                    this.setState({ changepasswordform: { ...this.state.changepasswordform, loading: false, message: error, messageType: "danger" } });
                })
            );
    }
    hadleCloseAlert = (e) => {
        this.setState({ changepasswordform: { ...this.state.changepasswordform, message: "", messageType: "" } });
    }
    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.changepasswordform.newPassword) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('passwordMinLength', (value) => {
            if (value.trim() !== "") {
                if (value.trim().length < 6) {
                    return false;
                }
            }
            return true;
        });
    }
    render() {
        const { changepasswordform } = this.state;

        return (
            <section className="innerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                            <div className="formsDiv">
                                <h1>Change Password
                                <p>You're logged in as {this.state.changepasswordform.userName}</p>
                                </h1>
                                {
                                    changepasswordform.message &&
                                    <Alert variant={changepasswordform.messageType} onClose={() => this.hadleCloseAlert(false)} dismissible>{changepasswordform.message}</Alert>
                                }
                                <ValidatorForm
                                    onSubmit={this.handleSubmit}
                                    className="form-horizontal"
                                >
                                    <div className="form-group1">
                                        <div className="row">
                                            <label className="col-sm-12 control-label">Current Password</label>
                                            <div className="col-sm-12">
                                                <TextValidator
                                                    name="oldPassword"
                                                    type="password"
                                                    validators={['required']}
                                                    errorMessages={['Current Password is Required.']}
                                                    value={changepasswordform.oldPassword}
                                                    className="form-control input-sm"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group1">
                                        <div className="row">
                                            <label className="col-sm-12 control-label">New Password</label>
                                            <div className="col-sm-12">
                                                <TextValidator
                                                    onChange={this.handleChange}
                                                    name="newPassword"
                                                    type="password"
                                                    validators={['required', 'passwordMinLength']}
                                                    errorMessages={['New Password is Required.', 'Please enter at least 6 characters.']}
                                                    value={changepasswordform.newPassword}
                                                    className="form-control input-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group1">
                                        <div className="row">
                                            <label className="col-sm-12 control-label">Confirm Password</label>
                                            <div className="col-sm-12">
                                                <TextValidator
                                                    onChange={this.handleChange}
                                                    name="confirmNewPassword"
                                                    type="password"
                                                    validators={['required', 'isPasswordMatch']}
                                                    errorMessages={['Confirm Password is Required.', 'Password Mismatch.']}
                                                    value={changepasswordform.confirmNewPassword}
                                                    className="form-control input-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group1"> <div className="row">
                                            <div className="col-sm-12">
                                                <button type="submit" className="btn btn-primary" disabled={changepasswordform.loading}>
                                                    Change Password
                                            </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </ValidatorForm>
                                {changepasswordform.loading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            logout: bindActionCreators(
                actions.logout,
                dispatch
            )
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);