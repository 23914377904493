import React, { Component } from "react";
import MaterialTable from "material-table";
import { apiService } from "../../services/api.service";
import { APP_URLS } from "../../config/api.config";
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';

class shareddocument extends Component {
    constructor() {
        super();
        this.state = {
            datalist: [],
            loading: false,
            isLoading: true
        };

    }
    componentDidMount() {
        const CourtLinkDocumentIdRequest = {
            DocumentId: -1,
            UserId: this.props.auth.user.UserId
        };
        apiService.post("GETDOCUMENTS", CourtLinkDocumentIdRequest).then(data => {
            if (data.Success && data.CourtLinkDocuments) {
                let details = data.CourtLinkDocuments;
                this.setState({
                    datalist: details
                });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    render() {
        const { isLoading } = this.state;
        return (
            <section className="innerPage">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="materialtableDiv">
                                <MaterialTable
                                    columns={[
                                        {
                                            title: "File Name",
                                            field: "FileName",
                                            render: rowData => (
                                                <button className='link-button' onClick={() => this.downloadDocument(rowData)}>{rowData.FileName}</button>

                                            )
                                        },
                                        {
                                            title: "Description",
                                            field: "Description"
                                        },
                                        {
                                            title: "Role",
                                            field: "Role"
                                        },
                                    ]}
                                    options={{
                                        pageSize: 25,
                                        pageSizeOptions: [5, 10, 25, 50, 100],
                                        toolbar: true,
                                        paging: true,
                                        paginationType: "stripe",
                                        headerStyle: {
                                            backgroundColor: "#C6C5B9"
                                        },
                                        actionsColumnIndex: -1,
                                        minBodyHeight:"325px",
                                        columnsButton:true
                                    }}
                                    isLoading={isLoading}
                                    data={this.state.datalist}
                                    localization={{
                                        pagination: {
                                            labelRowsSelect: "rows per page"
                                        }
                                    }}
                                    title=" Shared Documents"
                                />
                                {this.state.loading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
    downloadDocument = fileObject => {
        this.setState({ loading: true });
        const CourtLinkDocumentIdRequest = {
            DocumentId: fileObject.DocumentId,
            UserId: -1
        };
        apiService.post("DOWNLOADDOCUMENT", CourtLinkDocumentIdRequest).then(response => {
            if (response.Success && response.path) {
                window.open(APP_URLS.DOWNLOAD_API_URL + response.path);
            }
            else {
                this.props.actions.showAlert({ message: "File not Found.", variant: 'error' });
            }
            this.setState({ loading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    };

    editDocument = e => {

        this.props.history.push('/admin/adddocuments', { DocumentId: e.DocumentId })
    };

}
const mapStateToProps = state => {
    return {
        alert: state.alert,
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch
            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(shareddocument);

//export default documents;