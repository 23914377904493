import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../../services/api.service';
import { COMPANY_ID } from '../../../config/api.config';
class ProgramConfigurations extends Component {


    state = {
        datalist: [],
        isLoading : true
    };

    // changeValue = (row) => {
    changeValue(id, tabname, status) {
        status = (status === "Y") ? "N" : "Y"
        let selectedProgram = { ProgramId: id, CompanyId: COMPANY_ID, [tabname]: status, ActionPerformedBy: "Admin" };
        this.setState({isLoading:true});
        apiService.post('UPDATEPROGRAMCONFIGURATION', selectedProgram).then(response => {
            if (response.Success === true) {
                const { datalist } = this.state;
                this.setState({
                    datalist: datalist.map(x => x.ProgramId === id ? { ...x, [tabname]: status } : x)
                });
                this.props.onShowAlert({ message: "Status changed successfully", variant: 'success' });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.onShowAlert({ message: error, variant: 'error' });
        });
    }


    componentDidMount() {
        let programData = { CourtLinkProgramConfigId: -1, ProgramId: -1, CompanyId: COMPANY_ID };
        apiService.post('GETPROGRAMCONFIGURATION', programData).then(response => {
            if (response.Success && response.CourtLinkProgramConfigs != null) {
                let details = response.CourtLinkProgramConfigs;
                this.setState({
                    datalist: details
                });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.onShowAlert({ message: error, variant: 'error' });
        });
    }
    render() {
        const { datalist, isLoading } = this.state;

        return (
            <MaterialTable 
                columns={[
                    { title: "Program", field: "Program" },
                    {
                        title: "Violations", field: "TabDisplay_Violations",

                        render: rowData => <i className={'fa tick-mark ' + (rowData.TabDisplay_Violations === "Y" ? 'fa-check' : 'fa-close')} id={rowData.ProgramId} tabname="TabDisplay_Violations" onClick={() => this.changeValue(rowData.ProgramId, "TabDisplay_Violations", rowData.TabDisplay_Violations)} aria-hidden="true"></i>
                    },
                    {
                        title: "Procedures", field: "TabDisplay_Procedures",
                        render: rowData => <i className={'fa tick-mark ' + (rowData.TabDisplay_Procedures === "Y" ? 'fa-check' : 'fa-close')} id={rowData.ProgramId} tabname="TabDisplay_Procedures" onClick={() => this.changeValue(rowData.ProgramId, "TabDisplay_Procedures", rowData.TabDisplay_Procedures)} aria-hidden="true"></i> 
                    },
                    {
                        title: "Financials",
                        field: "TabDisplay_Financials",
                        render: rowData => <i className={'fa tick-mark ' + (rowData.TabDisplay_Financials === "Y" ? 'fa-check' : 'fa-close')} id={rowData.ProgramId} tabname="TabDisplay_Financials" onClick={() => this.changeValue(rowData.ProgramId, "TabDisplay_Financials", rowData.TabDisplay_Financials)} aria-hidden="true"></i>
                    },
                    {
                        title: "TreatmentGroups",
                        field: "TabDisplay_TreatmentGroups",
                        render: rowData => <i className={'fa tick-mark ' + (rowData.TabDisplay_TreatmentGroups === "Y" ? 'fa-check' : 'fa-close')} id={rowData.ProgramId} tabname="TabDisplay_TreatmentGroups" onClick={() => this.changeValue(rowData.ProgramId, "TabDisplay_TreatmentGroups", rowData.TabDisplay_TreatmentGroups)} aria-hidden="true"></i>
                    },

                ]}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    toolbar: true,
                    paging: true,
                    paginationType: "normal",
                    headerStyle: {
                        backgroundColor: '#C6C5B9',
                        color: 'secondary'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight:"325px",
                    columnsButton:true
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: "rows per page"
                    }
                }}
                isLoading = {isLoading}
                data={datalist}
                title="Program Configuration"
            />

        );
    }
}

export default ProgramConfigurations;

