import Home from "../containers/home";
import ChangePassword from "../containers/user/changepassword";
import UsersList from "../containers/admin/users/userslist";
import AddUser from "../containers/admin/users/adduser";
import ResetPassword from "../containers/admin/users/resetpassword";
import resetforgotpassword from '../containers/home/resetforgotpassword/resetforgotpassword';
import ConfigurationTabs from '../containers/admin/configuration/index';
import Documents from "../containers/admin/document/documents";
import AddDocument from "../containers/admin/document/adddocument";
import ReferralList from "../containers/clientdata/referrallist";
import ReferralCount from "../containers/clientdata/referralcount";
import ClientData from "../containers/clientdata/clientdetail";
import SharedDocumnet from "../containers/documents/shareddocumnets";


var appRoutes = [
    {
        path: "/",
        name: "Home",   
        component: Home,
        exact: true
    },
    {
        path: "/changepassword",
        name: "ChangePassword",
        component: ChangePassword,
        auth: true
    },
    {
        path: "/admin/user/users",
        name: "Users",
        component: UsersList,
        auth: true
    },
    {
        path: "/admin/user/adduser",
        name: "Add User",
        component: AddUser,
        auth: true
    },
    {
        path: "/admin/user/edit/:id",
        name: "Edit User",
        component: AddUser,
        auth: true
    },
    {
        path: "/admin/user/resetpassword/:id",
        name: "Rest Password",
        component: ResetPassword,
        auth: true
    },
    {
        path: "/account/manage/resetpassword",
        name: "Reset Password",
        component: resetforgotpassword

    },
    {
        path: "/admin/configuration",
        name: "ConfigurationTabs",
        component: ConfigurationTabs,
        auth: true
    },
    {
        path: "/admin/documents",
        name: "Document",
        component: Documents,
        auth: true
    },
    {
        path: "/admin/adddocuments",
        name: "Add Document",
        component: AddDocument,
        auth: true
    },
    {
        path: "/clientdata/referrallist/:programid",
        name: "Referral List",
        component: ReferralList,
        auth: true
    },
    {
        path: "/clientdata/referralcount",
        name: "Referral Count",
        component: ReferralCount,
        auth: true
    },
    {
        path: "/clientdata/clientdetail/:clientprogramenrollmentid",
        name: "Client Data",
        component: ClientData,
        auth: true
    },
    {
        path: "/documents/shareddocuments",
        name: "Shared Document",
        component: SharedDocumnet,
        auth: true

    }


];

export default appRoutes;