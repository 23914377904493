import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import { COMPANY_ID } from '../../config/api.config';

import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';

class SecurityProcedureTeb extends Component {


    state = {
        datalist: [],
        isLoading : true
    };


    componentDidMount() {

        let clientData = { ClientSecurityProcedureId: -1, ClientProgramEnrollmentId: this.props.clientprogramenrollmentid, CompanyId: COMPANY_ID };
        apiService.post('GETCOURTLINKCLIENTSECURITYPROCEDURES', clientData).then(response => {
            if (response.Success && response.CourtLinkClientSecurityProcedures != null) {
                let details = response.CourtLinkClientSecurityProcedures;
                this.props.onProceduresDataLoad(details);
                this.setState({
                    datalist: details
                });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }


    render() {

        const { datalist,isLoading } = this.state;

        return (
            <MaterialTable
                columns={[
                    {
                        title: "Procedure Type", field: "SecurityProcedureType"
                    },
                    {
                        title: "Status", field: "ProcedureStatus"
                    },
                    {
                        title: "Scheduled", field: "ScheduleDate",
                        render: rowData => <FormatDateTime
                            date={rowData.ScheduleDate}
                            format="MM/DD/YY"
                        />


                    },
                    {
                        title: "Completed", field: "CompletedDate",
                        render: rowData => <FormatDateTime
                            date={rowData.CompletedDate}
                            format="MM/DD/YY"
                        />
                    },
                    {
                        title: "Comments", field: "Comments"
                    }

                ]}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    toolbar: true,
                    paging: true,
                    paginationType: "normal",
                    headerStyle: {
                        backgroundColor: '#C6C5B9',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight:"325px",
                    columnsButton:true
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: "rows per page"
                    }
                }}
                isLoading = {isLoading}
                data={datalist}
                title="Security Procedure Data"
              
            />

        );
    }
}
const mapStateToProps = state => {
    return {
        alert: state.alert
    };
};
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SecurityProcedureTeb);

