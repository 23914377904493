import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import { COMPANY_ID } from '../../config/api.config';

import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';

class SecurityProcedureTeb extends Component {

    state = {
        datalist: [],
        isLoading: true
    };


    componentDidMount() {
        this.requestEquipmentData();
    }
    requestEquipmentData() {
        let serviceequipmentrequest = { ClientProgramServiceId: this.props.clientprogramserviceid, CompanyId: COMPANY_ID };
        apiService.post('GETCLIENTSERVICEEQUIPMENTS', serviceequipmentrequest).then(response => {
            if (response.Success && response.ClientServiceEquipments != null) {
                let details = response.ClientServiceEquipments;
                this.setState({
                    datalist: details
                });
            }
            else if (response.Success && response.ClientServiceEquipments === null) {
                this.setState({
                    datalist: []
                });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.clientprogramserviceid !== this.props.clientprogramserviceid) {
            this.requestEquipmentData();
        }
    }

    render() {
        const { datalist, isLoading } = this.state;

        return (
            <MaterialTable
                columns={[
                    {
                        title: "Equipment Type", field: "EquipmentType"
                    },
                    {
                        title: "Equipment Status", field: "EquipmentStatus"
                    },
                    {
                        title: "Serial Number", field: "SerialNumber"
                    },
                    {
                        title: "Theft Report Filed", field: "TheftReportFiled"
                    },
                    {
                        title: "Equipment Recovered", field: "EquipmentRecovered"
                    },
                    {
                        title: "Comments", field: "Comments"
                    },
                    {
                        title: "Active", field: "Active"
                    }

                ]}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    toolbar: true,
                    paging: true,
                    paginationType: "normal",
                    headerStyle: {
                        backgroundColor: '#C6C5B9',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight: "325px",
                    columnsButton: true
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: "rows per page"
                    }
                }}
                isLoading={isLoading}
                data={datalist}
                title="Equipment Details"
            />

        );
    }
}
const mapStateToProps = state => {
    return {
        alert: state.alert
    };
};
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SecurityProcedureTeb);

