import React, { Component } from 'react';
class About extends Component {
    render() { 
        return ( <section id="about">
        <div className="container">
           <div className="row"><div className="col-sm-12">
              <header className="section-header">
                 <h3>About Us</h3>
                 <p>Beta Alternatives offers a wide range of community based correctional programs
                    and misdemeanor probation and pre-trial supervision. We provide services in Yellowstone,
                    Carbon, and Stillwater Counties.
        </p>
              </header>
              <div className="about-container">
                 <h4>Beta aids over 5,000 clients annually. We offer supervision and treatment services designed to:</h4>
                 <ul>
                    <li>Reduce jail overcrowding.</li>
                    <li>Teach responsibility.</li>
                    <li>Keep indigent offenders out of jail.</li>
                    <li>Permit non-dangerous offenders to retain employment.</li>
                    <li>Save the taxpayer money.</li>
                 </ul>
              </div>
           </div>
           </div>
        </div>
     </section> );
    }
}
 
export default About;