import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import { COMPANY_ID } from '../../config/api.config';

import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';


import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
class SecurityProcedureTeb extends Component {
    state = {
        datalist: [],
        isLoading: true
    };
    componentDidMount() {
        this.requestViolationTabData();
    }
    requestViolationTabData() {
        let serviceviolationtabrequest = { ClientProgramServiceId: this.props.clientprogramserviceid, CompanyId: COMPANY_ID };
        apiService.post('GETCLIENTSERVICEVIOLATIONS', serviceviolationtabrequest).then(response => {
            if (response.Success && response.ClientServiceViolations != null) {
                let details = response.ClientServiceViolations;
                this.setState({
                    datalist: details
                });
            }
            else if (response.Success && response.ClientServiceViolations === null) {
                this.setState({
                    datalist: []
                });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clientprogramserviceid !== this.props.clientprogramserviceid) {
            this.requestViolationTabData();
        }
    }
    render() {

        const { datalist, isLoading } = this.state;

        return (
            <MaterialTable
                columns={[
                    {
                        title: "Violation Type", field: "ViolationType"
                    },
                    {
                        title: "Notification Method", field: "NotificationMethod"
                    },
                    {
                        title: "Violation Date", field: "ViolationDate",
                        render: rowData => <FormatDateTime
                            date={rowData.ViolationDate}
                            format="MM/DD/YY"
                        />
                    },
                    {
                        title: "Comments", field: "Comments"
                    },
                    {
                        title: "Resolution", field: "Resolution"
                    },
                    {
                        title: "Active", field: "Active"
                    }

                ]}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    toolbar: true,
                    paging: true,
                    paginationType: "normal",
                    headerStyle: {
                        backgroundColor: '#C6C5B9',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight: "325px",
                    columnsButton: true
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: "rows per page"
                    }
                }}
                isLoading={isLoading}
                data={datalist}
                title="Violation Data"
            />

        );
    }
}
const mapStateToProps = state => {
    return {
        alert: state.alert
    };
};
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SecurityProcedureTeb);

