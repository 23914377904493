import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Custom components
import * as actions from '../../store/actions'
import CircularProgress from '@material-ui/core/CircularProgress';
import { apiService } from '../../services/api.service'
import Alert from 'react-bootstrap/Alert';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../shared/components/ui/form/text-validator';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginform: {
                username: "",
                password: "",
                remember: false,
                loading: false,
                errorMessage: ""
            }
        };
    }

    handleChange = (e) => {
        
        const { loginform } = this.state;

        if (e.target.name === 'remember') {
            loginform[e.target.name] = e.target.checked;
        } else {
            loginform[e.target.name] = e.target.value;
        }
        this.setState({ loginform });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { loginform } = this.state;
        this.setState({ loginform: { ...this.state.loginform, loading: true } });
        apiService.login(loginform.username, loginform.password, loginform.remember)
            .then(response => {
                
                if (response.Success) {
                    this.props.actions.loginSuccess(response);
                    if (response.Roles && response.Roles.indexOf('Admin') !== -1) {
                        this.setState({ loginform: { ...this.state.loginform, loading: false, username: "", password: "", remember: "", errorMessage: "" } });
                    } else {
                        this.props.onRedirect('/clientdata/referrallist/-1');
                    }
                }
                else {
                    this.setState({ loginform: { ...this.state.loginform, loading: false, errorMessage: response.Message } });
                }
            },
                error => this.setState(prevState => {
                    this.setState({ loginform: { ...this.state.loginform, loading: false, errorMessage: error } });
                })

            );
    }
    hadleCloseAlert = (e) => {
        this.setState({ loginform: { ...this.state.loginform, errorMessage: "" } });
    }
    render() {
        
        const { loginform } = this.state;
        return (
            <section id="section-login">
                <h5 className="card-title text-center">
                    Sign In
                    {
                        loginform.errorMessage &&
                        <Alert variant="danger" onClose={() => this.hadleCloseAlert(false)} dismissible>{loginform.errorMessage}</Alert>
                    }
                </h5>
                <ValidatorForm
                    onSubmit={this.handleSubmit}
                    className="form-horizontal">
                    <TextValidator
                        onChange={this.handleChange}
                        name="username"
                        type="text"
                        validators={['required']}
                        errorMessages={['UserName is Required.']}
                        value={loginform.username}
                        className="form-control"
                        placeholder="UserName"
                    />
                    <TextValidator
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        validators={['required']}
                        errorMessages={['Password is Required.']}
                        value={loginform.password}
                        className="form-control"
                        placeholder="Password"
                    />
                    <div className="Form">
                        <label className="checkbox">
                            <input type="checkbox" name="remember" checked={loginform.remember}
                                onChange={this.handleChange} /><span className="primary"></span>
                        </label>
                        <span className="checkActive">Remember Password</span>
                    </div>
                    <button className="btn btn-lg btn-primary btn-block text-uppercase home-button" disabled={loginform.loading} type="submit">
                        {/* {loginform.loading && <div class="spinner-border text-success"></div>} */}
                        Sign in
                    </button>
                    <hr />
                    <div className="text-center">
                        <button data-signin="reset" className='link-button' onClick={this.props.onForgotPasswordForm}>Forgot your password?</button>
                    </div>
                </ValidatorForm>
                {loginform.loading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            loginSuccess: bindActionCreators(
                actions.loginSuccess,
                dispatch
            )
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);