import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";
import { COMPANY_ID } from '../../config/api.config';

import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";

class ReferralList extends Component {

    constructor(props) {
        super(props);
        let {auth, match } = this.props;
       
        //  const programId = match.params.programid;
        this.state = {
            datalist: [],
            filteredlist: [],
            value: "2",
            isLoading: true,
            programId: match.params.programid,
            userId: auth.user.UserId,
            selectedvalue: localStorage.getItem('programfilter') ? localStorage.getItem('programfilter') : "0"
        };
    }
    componentDidMount() {
        let clientData = { CompanyId: COMPANY_ID, ProgramId: this.state.programId, UserId: this.state.userId };

        apiService.post('GETCOURTLINKREFERRALLIST', clientData).then(response => {
            if (response.Success && response.CourtLinkReferralLists) {
                let details = response.CourtLinkReferralLists;
                this.setState({
                    datalist: details,
                    filteredlist: details
                });
                this.FilterData(this.state.selectedvalue);
               
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error', });
        });
    }

    FilterData(filterValue) {
        var selectedOption = filterValue;
        const { datalist } = this.state;
        var filterDataList = [];
        if (selectedOption === "0") {
            filterDataList = datalist.filter(function (e) { return e.ActualEndDate == null; });
            this.setState({
                filteredlist: filterDataList,
                selectedvalue: selectedOption
            });
        }
        else if (selectedOption === "1") {
            filterDataList = datalist.filter(function (e) { return e.ActualEndDate != null; });
            this.setState({
                filteredlist: filterDataList,
                selectedvalue: selectedOption
            });
        }
        else {
            filterDataList = datalist.filter(function (e) { return e.Status === "ALL"; });
            this.setState({
                filteredlist: datalist,
                selectedvalue: selectedOption
            });
        }
        if (selectedOption !== '') {
            localStorage.setItem('programfilter', selectedOption);
        }
    }

    openClientDetail = (id) => {

        this.props.history.push('/clientdata/clientdetail/' + id);
    }

    render() {

        const { filteredlist, isLoading } = this.state;

        return (
            <section className="innerPage">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="clientDropdown">
                                <div className="back_button">
                                    <Link to="/clientdata/referralcount"><i className="fa fa-arrow-left" aria-hidden="true" /> Back to Program Counts</Link>
                                </div>
                                <select className="form-control"
                                    defaultValue={this.state.selectedvalue}
                                    onChange={e => this.FilterData(e.target.value)}>
                                    <option value="0"> Active clients only</option>
                                    <option value="1">Termed clients only</option>
                                    <option value="2">All clients</option>
                                </select>
                            </div>
                            <MaterialTable
                                columns={[
                                    {
                                        title: "Client Name", field: "ClientName",
                                        render: rowData => <button className='link-button' onClick={() => this.openClientDetail(rowData.ClientProgramEnrollmentId)}>{rowData.ClientName}</button>
                                    },
                                    {
                                        title: "Program", field: "Program",
                                    },
                                    {
                                        title: "Dockets", field: "Dockets",
                                    },
                                    {
                                        title: "ReferralDate", field: "StartDate",
                                        render: rowData => <FormatDateTime
                                            date={rowData.StartDate}
                                            format="MM/DD/YY"
                                        />
                                    },
                                    {
                                        title: "Intake Date", field: "IntakeDate",
                                        render: rowData => <FormatDateTime
                                            date={rowData.IntakeDate}
                                            format="MM/DD/YY"
                                        />
                                    },
                                    {
                                        title: "Completion Date", field: "ActualEndDate",
                                        render: rowData => <FormatDateTime
                                            date={rowData.ActualEndDate}
                                            format="MM/DD/YY"
                                        />
                                    },
                                    {
                                        title: "Program Status", field: "ProgramStatus",
                                    },
                                ]}
                                options={{
                                    pageSize: 25,
                                    pageSizeOptions: [5, 10, 25, 50, 100],
                                    toolbar: true,
                                    paging: true,
                                    paginationType: "normal",
                                    headerStyle: {
                                        backgroundColor: '#C6C5B9'
                                    },
                                    actionsColumnIndex: -1,
                                    minBodyHeight: "325px",
                                    columnsButton: true
                                }}
                                localization={{
                                    pagination: {
                                        labelRowsSelect: "rows per page"
                                    }
                                }}
                                isLoading={isLoading}
                                data={filteredlist}
                                title="Referral List"

                            />
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

const mapStateToProps = state => {
    return {
        alert: state.alert

    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch
            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ReferralList);


