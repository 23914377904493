import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
import { COMPANY_ID } from '../../config/api.config';

import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';


class SecurityProcedureTeb extends Component {


    state = {
        datalist: [],
        isLoading: true
    };


    componentDidMount() {
        this.requestServicePeriodData();
    }
    requestServicePeriodData() {
        let serviceserviceperiodtabrequest = { ClientProgramServiceId: this.props.clientprogramserviceid, CompanyId: COMPANY_ID };
        apiService.post('GETCLIENTSERVICEPERIODS', serviceserviceperiodtabrequest).then(response => {
            if (response.Success && response.ClientServicePeriods !== null) {
                let details = response.ClientServicePeriods;
                this.setState({
                    datalist: details
                });
            }
            else if (response.Success && response.ClientServicePeriods === null) {
                this.setState({
                    datalist: []
                });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.actions.props.showAlert({ message: error, variant: 'error' });
        });
    }


    componentDidUpdate(prevProps, prevState) {
    
        if (prevProps.clientprogramserviceid !== this.props.clientprogramserviceid) {
            this.requestServicePeriodData();
        }
    }


    render() {

        const { datalist, isLoading } = this.state;

        return (
            <MaterialTable
                columns={[
                    {
                        title: "Start Date", field: "StartDate",
                        render: rowData => <FormatDateTime
                            date={rowData.StartDate}
                            format="MM/DD/YY"
                        />
                    },
                    {
                        title: "End Date", field: "EndDate",
                        render: rowData => <FormatDateTime
                            date={rowData.EndDate}
                            format="MM/DD/YY"
                        />

                    },
                    {
                        title: "Service Status", field: "ServiceStatus"
                    },
                    {
                        title: "Service Billing", field: "ServiceBilling"
                    },
                    {
                        title: "Comments", field: "Comments"
                    },
                    {
                        title: "Active", field: "Active"
                    }

                ]}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    toolbar: true,
                    paging: true,
                    paginationType: "normal",
                    headerStyle: {
                        backgroundColor: '#C6C5B9',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight: "325px",
                    columnsButton: true
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: "rows per page"
                    }
                }}
                isLoading={isLoading}
                data={datalist}
                title="Service Period Data"
            />

        );
    }
}
const mapStateToProps = state => {
    return {
        alert: state.alert
    };
};
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(SecurityProcedureTeb);

