import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { COMPANY_ID } from '../../config/api.config';

class ReferralCount extends Component {
    constructor(props) {
        super(props);
        let { auth } = this.props;
        this.state = {
            datalist: [],
            filteredlist: [],
            value: 0,
            selectedvalue: localStorage.getItem('programfilter') ? localStorage.getItem('programfilter') : "0",
            isLoading: true,
            userId: auth.user.UserId

        };

    }
    FilterData(filterValue) {
        
        var selectedOption = filterValue;
        const { datalist } = this.state;
        var filterDataList = [];
        if (selectedOption === "0") {
            filterDataList = datalist.filter(function (e) { return e.Status === "ACTIVE"; });
            this.setState({
                selectedvalue: selectedOption
            });
        }
        else if (selectedOption === "1") {
            filterDataList = datalist.filter(function (e) { return e.Status === "INACTIVE"; });
            this.setState({
                selectedvalue: selectedOption
            });
        }
        else {
            filterDataList = datalist.filter(function (e) { return e.Status === "ALL"; });
            this.setState({
                selectedvalue: selectedOption
            });
        }
        if (selectedOption !== '') {
            localStorage.setItem('programfilter', selectedOption);
        }
        this.setState({ filteredlist: filterDataList });
    }
    componentDidMount() {

        let clientData = { CompanyId: COMPANY_ID, UserId: this.state.userId };

        apiService.post('GETCOURTLINKREFERRALCOUNTBYPROGRAMS', clientData).then(response => {
            if (response.Success && response.CourtLinkReferralCountByPrograms) {
                let details = response.CourtLinkReferralCountByPrograms;
                this.setState({
                    datalist: details,
                });
                this.FilterData(this.state.selectedvalue);
            }
            this.setState({ isLoading: false });
        }, error => {
            this.props.actions.showAlert({ message: error, variant: 'error' });
            this.setState({ isLoading: false });
        });
    }
    openreferrallist = (id) => {

        this.props.history.push('/clientdata/referrallist/' + id);
    }

    render() {
        const { filteredlist, isLoading } = this.state;
        return (
            <section className="innerPage">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="clientDropdown">
                                <select className="form-control"
                                    defaultValue={this.state.selectedvalue}
                                    onChange={e => this.FilterData(e.target.value)}>
                                    <option value="0"> Active clients only</option>
                                    <option value="1">Termed clients only</option>
                                    <option value="2">All clients</option>
                                </select>
                            </div>
                            <MaterialTable
                                columns={[
                                    {
                                        title: "Program Name", field: "Program",
                                        render: rowData => <button className='link-button' onClick={() => this.openreferrallist(rowData.ProgramId)}>{rowData.Program}</button>
                                    },
                                    {
                                        title: "Program Count", field: "ProgramCount"
                                    }
                                ]}
                                options={{
                                    pageSize: 25,
                                    pageSizeOptions: [5, 10, 25, 50, 100],
                                    toolbar: true,
                                    paging: true,
                                    paginationType: "normal",
                                    headerStyle: {
                                        backgroundColor: '#C6C5B9'
                                    },
                                    actionsColumnIndex: -1,
                                    loadingType: "overlay",
                                    minBodyHeight: "325px",
                                    columnsButton: true
                                }}
                                localization={{
                                    pagination: {
                                        labelRowsSelect: "rows per page"
                                    }

                                }}
                                isLoading={isLoading}
                                data={filteredlist}
                                title="Referral Count By Program"

                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
const mapStateToProps = state => {
    return {
        alert: state.alert

    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch
            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ReferralCount);

//export default ReferralCount;


