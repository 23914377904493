import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../../services/api.service'
import { COMPANY_ID } from '../../../config/api.config'
class ServiceConfigurations extends Component {
    state = {
        datalist: [],
        isLoading : true
    };

    // changeValue = (row) => {
    changeValue(id, tabname, status) {

        status = (status === "Y") ? "N" : "Y";
        let selectedProgram = { ServiceId: id, CompanyId: COMPANY_ID, [tabname]: status, ActionPerformedBy: "Admin" };
        this.setState({ isLoading: true });

        apiService.post('UPDATESERVICECONFIGURATION', selectedProgram).then(response => {

            if (response.Success === true) {
                const { datalist } = this.state;
                this.setState({
                    datalist: datalist.map(x => x.ServiceId === id ? { ...x, [tabname]: status } : x)
                });
                this.props.onShowAlert({ message: "Status changed successfully", variant: 'success' });
            }
            this.setState({ isLoading: false });

        }, error => {
            this.setState({ isLoading: false });
            this.props.onShowAlert({ message: error, variant: 'error' });
        });
    }


    componentDidMount() {
        
        let programData = { CourtLinkServiceConfigId: -1, ServiceId: -1, CompanyId: COMPANY_ID };
        apiService.post('GETSERVICECONFIGURATION', programData).then(response => {
            if (response.Success && response.CourtLinkServiceConfigs != null) {
                let details = response.CourtLinkServiceConfigs;
                this.setState({
                    datalist: details
                });
            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.onShowAlert({ message: error, variant: 'error' });
        });
    }


    render() {
        
        const { datalist,isLoading } = this.state;

        return (
            <MaterialTable
                columns={[
                    { title: "Service", field: "Service" },
                    {
                        title: "Equipment", field: "TabDisplay_Equipment",

                        render: rowData => <i className={'fa tick-mark ' + (rowData.TabDisplay_Equipment === "Y" ? 'fa-check' : 'fa-close')} id={rowData.ServiceId} tabname="TabDisplay_Equipment" onClick={() => this.changeValue(rowData.ServiceId, "TabDisplay_Equipment", rowData.TabDisplay_Equipment)} aria-hidden="true"></i>
                    },
                    {
                        title: "Placements", field: "TabDisplay_Placements",
                        render: rowData => <i className={'fa tick-mark ' + (rowData.TabDisplay_Placements === "Y" ? 'fa-check' : 'fa-close')} id={rowData.ServiceId} tabname="TabDisplay_Placements" onClick={() => this.changeValue(rowData.ServiceId, "TabDisplay_Placements", rowData.TabDisplay_Placements)} aria-hidden="true"></i>
                    },
                    {
                        title: "ServicePeriods",
                        field: "TabDisplay_ServicePeriods",
                        render: rowData => <i className={'fa tick-mark ' + (rowData.TabDisplay_ServicePeriods === "Y" ? 'fa-check' : 'fa-close')} id={rowData.ServiceId} tabname="TabDisplay_ServicePeriods" onClick={() => this.changeValue(rowData.ServiceId, "TabDisplay_ServicePeriods", rowData.TabDisplay_ServicePeriods)} aria-hidden="true"></i>
                    },
                    {
                        title: "Violations",
                        field: "TabDisplay_Violations",
                        render: rowData => <i className={'fa tick-mark ' + (rowData.TabDisplay_Violations === "Y" ? 'fa-check' : 'fa-close')} id={rowData.ServiceId} tabname="TabDisplay_Violations" onClick={() => this.changeValue(rowData.ServiceId, "TabDisplay_Violations", rowData.TabDisplay_Violations)} aria-hidden="true"></i>
                    },

                ]}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    toolbar: true,
                    paging: true,
                    paginationType: "normal",
                    headerStyle: {
                        backgroundColor: '#C6C5B9',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight:"325px",
                    columnsButton:true
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: "rows per page"
                    }
                }}
                isLoading = {isLoading}
                data={datalist}
                title="Service Configuration"
              
            />
        );
    }
}

export default ServiceConfigurations;

