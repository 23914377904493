import React, { Component } from 'react';
import { apiService } from '../../services/api.service';
import Alert from 'react-bootstrap/Alert';
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../shared/components/ui/form/text-validator';
import CircularProgress from '@material-ui/core/CircularProgress';

class ForgotPassword extends Component {
    state = {
        forgetpasswordform: {
            username: "",
            loading: false,
            message: "",
            messageType: ""
        }
    }

    handleChange = (e) => {
        const { forgetpasswordform } = this.state;
        forgetpasswordform[e.target.name] = e.target.value;
        this.setState({ forgetpasswordform });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { forgetpasswordform } = this.state;

        this.setState({ forgetpasswordform: { ...this.state.forgetpasswordform, loading: true } });

        let request = {
            username: forgetpasswordform.username
        };
        apiService.post('SENDRESTPASSWOREMAIL', request)
            .then(response => {

                if (response.Success) {
                    this.setState({ forgetpasswordform: { ...this.state.forgetpasswordform, loading: false, message: response.Message, username: "", messageType: "success" } });
                }
                else {
                    this.setState({ forgetpasswordform: { ...this.state.forgetpasswordform, loading: false, message: response.Message, messageType: "danger" } });
                }
            },
                error => this.setState(prevState => {
                    this.setState({ forgetpasswordform: { ...this.state.forgetpasswordform, loading: false, message: error, messageType: "danger" } });
                })
            );
    }
    hadleCloseAlert = (e) => {
        this.setState({ forgetpasswordform: { ...this.state.forgetpasswordform, message: "", messageType: "" } });
    }
    render() {
        const { forgetpasswordform } = this.state;
        return (<section id="section-forget-password">
            <h5 className="card-title text-center ">
                Forgot Password
                {
                    forgetpasswordform.message &&
                    <Alert variant={forgetpasswordform.messageType} onClose={() => this.hadleCloseAlert(false)} dismissible>{forgetpasswordform.message}</Alert>
                }
            </h5>
            <p>
                Enter your user name below. If you are a registered user, an email with a link to reset your password will be sent to the email address on record.
            </p>
            <ValidatorForm
                onSubmit={this.handleSubmit}
                className="form-horizontal"
            >
                <TextValidator
                    onChange={this.handleChange}
                    name="username"
                    type="text"
                    validators={['required']}
                    errorMessages={['UserName is Required.']}
                    value={forgetpasswordform.username}
                    className="form-control"
                    placeholder="User Name"
                />
                <button className="btn btn-lg  btn-primary  btn-block text-uppercase home-button" disabled={forgetpasswordform.loading} type="submit">
                    Send Recovery Email
                </button>
                <hr />
                <div className="text-center">
                    <button data-signin="login" className='link-button' onClick={this.props.onForgotPasswordForm}>Back to log in</button>
                </div>
            </ValidatorForm>
            {forgetpasswordform.loading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
        </section>);
    }
}

export default ForgotPassword;