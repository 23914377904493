import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
class Feactures extends Component {
    state = {}
    render() {
        return (<section id="why-us" className="wow fadeIn">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <header className="section-header">
                            <h3>CourtLink Features</h3>
                            <p></p>
                        </header>
                    </div>
                </div>
                <div className="row row-eq-height justify-content-center">
                    <div className="col-lg-4 mb-4">
                        <div className="card wow bounceInUp">
                            <i className="fa fa-diamond"></i>
                            <div className="card-body">
                                <h5 className="card-title">Active Referrals</h5>
                                <p className="card-text">See the list of active clients in Alternatives programs referred from your court. Includes a
                                   count by program and a list of clients with current program status and scheduled completion
                                   date.
              </p>
                                {this.props.auth.loggedIn && <Link to="/clientdata/referrallist/-1">Go to Client List</Link>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card wow bounceInUp">
                            <i className="fa fa-language"></i>
                            <div className="card-body">
                                <h5 className="card-title">Shared Documents</h5>
                                <p className="card-text">Documents shared with you from Alternatives staff.</p>
                                {this.props.auth.loggedIn && <Link to="/documents/shareddocuments">Go to Shared Documents</Link>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <div className="card wow bounceInUp">
                            <i className="fa fa-object-group"></i>
                            <div className="card-body">
                                <h5 className="card-title">Equipment Inventory (coming soon...)</h5>
                                <p className="card-text">See the number of units immediately available for assignment. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps)(Feactures);
// export default Feactures;