import React, { Component } from 'react';
import {Redirect } from "react-router-dom";
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../../shared/components/ui/form/text-validator';
import { apiService } from '../../../services/api.service';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

class resetforgotpassword extends Component {
    constructor(props) {
        super(props);
        // const queryString = require('query-string');
        // const parsed = queryString.parse(props.location.search);
        const params = new URLSearchParams(props.location.search);
        const email = params.get('email');
        const token = params.get('token')
        console.log(email + " " + token)
        this.state = {
            user: {
                email: email,
                NewPassword: '',
                confirmNewPassword: '',
                token: token
            },
            message: "",
            messageType: "",
            loading: false
        }
    }
    handleChange = (e) => {
        const { user } = this.state;
        user[e.target.name] = e.target.value;
        this.setState({ user });
    }
    handleSubmit = () => {
        const { user } = this.state
        const data = {
            Email: user.email,
            Password: user.newPassword,
            Token: user.token
        }
        console.log(data)
        this.setState({ loading: true });
        apiService.post('FORGETPASSWORD', data).then(response => {
            
                this.setState({ loading: false });
                if (response.Success) {
                    this.setState({ loading: false, message:response.Message, messageType: "success", user: { ...this.state.user,  newPassword: "", confirmNewPassword: ""}})
                    setTimeout(
                        function() {
                            this.props.history.push('/');
                        }
                        .bind(this),
                        5000
                    );
                ;
            }
            else {
                this.setState({ loading: false, message: response.Message, messageType: "danger" });
            }
        },
            error => this.setState(prevState => {
                this.setState({ loading: false, message: error, messageType: "danger" });
            })
        );

    }
    hadleCloseAlert = (e) => {
        this.setState({message: "", messageType: ""});
    }
    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.newPassword) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('passwordMinLength', (value) => {
            if (value.trim() !== "") {
                if (value.trim().length < 6) {
                    return false;
                }
             }
             return true;
        });
    }
    render() {
        const { user,messageType,message,loading } = this.state;
        if(this.props.auth.user)
        return <Redirect to="/" />
        return (
            <section  className="innerPage">
            <div className="container">
                  <div className="row">
                     <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                        <div className="formsDiv" id="reset-forgot-password">
                <h1>Reset Password</h1>
                {
                    message &&
                    <Alert variant={messageType} onClose={() => this.hadleCloseAlert(false)} dismissible>{message}</Alert>
                }
                <ValidatorForm
                    onSubmit={this.handleSubmit}
                    className="form-horizontal"
                >
                    <div className="form-group1">
                    <div className="row">
                        <label className="col-sm-12 control-label">New Password </label>
                        <div className="col-sm-12">
                            <TextValidator
                                onChange={this.handleChange}
                                name="newPassword"
                                type="password"
                                validators={['required','passwordMinLength']}
                                errorMessages={['New Password is Required.','Please enter at least 6 characters.']}
                                value={user.newPassword}
                                className="form-control input-sm"
                            />
                        </div>
                    </div>
                    </div>
                    <div className="form-group1">
                    <div className="row">
                        <label className="col-sm-12 control-label">Confirm Password </label>
                        <div className="col-sm-12">
                            <TextValidator
                                onChange={this.handleChange}
                                name="confirmNewPassword"
                                type="password"
                                validators={['required', 'isPasswordMatch']}
                                errorMessages={['This field is Required.', 'Password Mismatch.']}
                                value={user.confirmNewPassword}
                                className="form-control input-sm"
                            />
                        </div>
                    </div>
                    </div>
                    <div className="form-group1"><div className="row">
                        <div className="col-sm-12">
                            <button type="submit" className="btn btn-primary" disabled={loading} >Reset Password</button>
                        </div>
                    </div>
                </div>
                </ValidatorForm>
                {loading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
            </div></div></div></div>
            </section>
        );
    }
}
const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};
export default connect(mapStateToProps)(resetforgotpassword);