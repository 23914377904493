const API_URL = process.env.REACT_APP_API_URL + '/CourtLinkAPI/';
const DOWNLOAD_API_URL = process.env.REACT_APP_API_URL;

const API_ENDPOINTS = {
    LOGIN: 'UserLogin',
    SENDRESTPASSWOREMAIL: 'SendResetPasswordEmail',
    GETROLES: 'GetRoles',
    CREATEUPDATEUSER: 'CreateUpdateUser',
    GETUSERS: 'GetUsers',
    RESETPASSWORD: 'ResetPassword',
    CHANGEPASSWORD: 'ChangePassword',
    FORGETPASSWORD: 'ResetForgetPassword',
    GETPROGRAMCONFIGURATION: 'GetCourtLinkProgramConfig',
    GETSERVICECONFIGURATION: 'GetCourtLinkServiceConfig',
    UPDATEPROGRAMCONFIGURATION: 'CreateUpdateCourtLinkProgramConfig',
    UPDATESERVICECONFIGURATION: 'CreateUpdateCourtLinkServiceConfig',
    ADDDOCUMENT: "CreateUpdateDocument",
    GETDOCUMENTS: "GetDocuments",
    DOWNLOADDOCUMENT: "Download",
    GETCOURTLINKDETAILS: "GetCourtLinkClientDetails",
    GETCOURTLINKCLIENTPROGRAMVIOLATIONS: "GetCourtLinkClientProgramViolations",
    GETCOURTLINKCLIENTCOMMUNITYFINANCIALDETAILS: "GetCourtLinkClientCommunityFinancialDetails",
    GETCOURTLINKCLIENTSECURITYPROCEDURES: "GetCourtLinkClientSecurityProcedures",
    GETCOURTLINKSESSIONNOTES: "GetCourtLinkClientSessionNotes",
    GETCLIENTDATAPDF: "GetClientDataPdf",
    GETCLIENTPROGRAMSERVICELEFTTABS: "GetClientProgramServiceLeftTabs",
    GETCLIENTPROGRAMSERVICES: "GetClientProgramServices",
    GETCLIENTSERVICEEQUIPMENTS: "GetClientServiceEquipments",
    GETCLIENTSERVICEPERIODS: "GetClientServicePeriods",
    GETCLIENTSERVICEPLACEMENTS: "GetClientServicePlacements",
    GETCLIENTSERVICEVIOLATIONS: "GetClientServiceViolations",
    GETCOURTLINKSHAREDDOCUMNETS: "GetCourtLinkSharedDocumnets",
    GETCOURTLINKREFERRALCOUNTBYPROGRAMS: "GetCourtLinkReferralCountByPrograms",
    GETCOURTLINKREFERRALLIST: "GetCourtLinkReferralList"
}

export const getApiUrl = (key) => {
    return API_URL + API_ENDPOINTS[key];
}
export const source = process.env.REACT_APP_SOURCE;

export const APP_URLS = {
    DOWNLOAD_API_URL
};
export const COMPANY_ID = process.env.REACT_APP_COMPANY_ID;

export const TIMEOUT_IN_MINUTES = process.env.REACT_APP_TIMEOUT_IN_MINUTES;
export const TIMEOUT_IN_DAYS = process.env.REACT_APP_TIMEOUT_IN_DAYS;
export const GOOGLE_ANALYTICS_TRACKINGID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGID;