import React, { Component, Fragment } from "react";
import { apiService } from "../../../services/api.service";
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';


class adddocument extends Component {
  constructor(props) {
    super(props);
    // let { match, auth } = this.props;
    this.state = {
      description: "",
      selectedFile: "",
      roles: [],
      selectedRole: 1,
      submitted: false,
      loading: false,
      DocumentId: -1,
      newFile: false,
      buttonName: "Upload"

    };
  }
  componentDidMount() {
    let CourtLinkDocumentIdRequest = this.props.location.state
    if (CourtLinkDocumentIdRequest) {
      apiService.post("GETDOCUMENTS", CourtLinkDocumentIdRequest).then(data => {
            let details = data.CourtLinkDocuments;
            details.map(detail => {
              this.setState({
                description: detail.Description,
                selectedRole: detail.RoleId,
                selectedFile: detail.FileName,
                DocumentId: detail.DocumentId,
                filName: detail.FileName,
                buttonName: "Update"
              });
              return 0;
            })
      });
    }

    const CourtLinkRoleIdRequest = {
      RoleId: -1
    };
    apiService.post("GETROLES", CourtLinkRoleIdRequest).then(data => {
          let rolesFromApi = data.CourtLinkRoles.map(role => {
            return { value: role };
          });
          this.setState({ roles: rolesFromApi });
    });

  }

  render() {
    return (
      <Fragment>

        <section className="innerPage">
          <div className="container">
            <div className="row">
                  <div className="col-md-6 offset-md-3">
                  <div className="formsDiv">
                    <h1>{this.state.buttonName === "Update" ? 'Edit' : this.state.buttonName} Documents</h1>
                    <form>
                      <div className="col-sm-12"><div className="form-group1">
                        <label className="control-label">Description</label>
                        <div className="Form">
                          <div className="input-group">
                            <input
                              type="input"
                             
                              name="description"
                              id="inputDescription"
                              className="form-control"
                              onChange={e => this.handleEvent(e)}
                              value={this.state.description}
                            />
                          </div>
                          {this.state.submitted && !this.state.description && (
                            <div className="error">
                              Description is required
                          </div>
                          )}
                        </div>
                      </div>
                      </div>
                      <div className="col-sm-12">
                      <div className="form-group1">
                        <label className="control-label">File</label>
                        <div className="Form">
                          <div className="input-group">
                            <input
                              type="file"
                              className="form-control"
                              name="file"
                              onChange={this.onChangeHandler}
                            />
                          </div>
                          {this.state.submitted && !this.state.selectedFile && (
                            <div className="error">
                              Please select a file to upload
                        </div>
                          )}
                        </div>
                      </div>
                      </div>
                      <div className="col-sm-12">
                      <div className="form-group">
                        <label className="control-label">Role</label>
                        <select
                          className="form-control"
                          id="role"
                          value={this.state.selectedRole}
                          onChange={e =>
                            this.setState({
                              selectedRole: e.target.value,
                              validationError:
                                e.target.value === "" ? "You must select your role" : ""
                            })
                          }
                        >
                          {this.state.roles.map(role => (
                            <option key={role.value.RoleId} value={role.value.RoleId}>
                              {role.value.Role}
                            </option>
                          ))}
                        </select>
                      </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group1">
                          <button className="btn btn-primary" onClick={this.handleSubmit} disabled={this.state.loading}>{this.state.buttonName}</button>
                          <button className="btn btn-primary" type="button" onClick={this.onCancel}>Cancel</button>
                        </div>
                      </div>
                    </form>
                    {this.state.loading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
                  </div>
                </div>
              </div>
            </div>
        </section>

      </Fragment>
    );
  }
  onChangeHandler = event => {
    if (event.target.files[0]) {
      this.setState({
        selectedFile: event.target.files[0],
        fileName: event.target.files[0].Name,
        newFile: true
      });
    }

  };
  handleEvent = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { description, selectedFile } = this.state;
    const { auth } = this.props;
    if (description && selectedFile) {
      this.setState({ loading: true });
      let File;
      var formData = new FormData();


      if (this.state.newFile === true) {
        File = this.state.selectedFile.name
        formData.append("file", this.state.selectedFile);

      }
      else {
        File = this.state.selectedFile
      }

      var courtLinkDocumentRequest = {
        Description: this.state.description,
        RoleId: this.state.selectedRole,
        ActionPerformedBy: auth.user.UserName,
        DocumentId: this.state.DocumentId,
        NewFile: this.state.newFile,
        FileName: File
      }


      formData.append('data', JSON.stringify(courtLinkDocumentRequest));
      return apiService.postFile("ADDDOCUMENT", formData).then((data) => {

        if (data.Success === true) {
          this.setState({
            loading: false,
            description: "",
            selectedFile: "",
            selectedRole: 1,
            submitted: false,
          });
          this.state.buttonName === 'Edit' ? this.props.actions.showAlert({ message: data.Message, variant: 'success' }) 
          :this.props.actions.showAlert({ message: data.Message, variant: 'success' });
          this.props.history.push('/admin/documents');

        }
      }, error => {
        this.setState({ loading: false });
      });
    }
  };
  onCancel = () => {
    this.props.history.push("/admin/documents");
  };

}
const mapStateToProps = state => {
  return {
    auth: state.auth,
    alert: state.alert
  }
};
const mapDispatchToProps = dispatch => {
  return {
      actions: {
          showAlert: bindActionCreators(
              actions.showAlert,
              dispatch
          )
      }
  }
};
export default connect(mapStateToProps,mapDispatchToProps)(adddocument);