import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiService } from "../../services/api.service";
import { APP_URLS } from "../../config/api.config";
class Contact extends Component {
    state = {}
    render() {
        return (
            <section id="contact" className="wow fadeIn">
                <div className="container">
                    <div className="row"> <div className="col-sm-12">
                        <div className="section-header">
                            <h3>Contact Us</h3>
                            <p>Alternatives offers the services available on CourtLink to authorized administrators only. If you need support with
                               something on CourtLink or would like to request access to CourtLink, please contact us at the phone number or email
                               address below.
                                </p>
                        </div>
                    </div>
                        <div className="col-sm-12">
                            <div className="support">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="info">
                                            <i className="fa fa-map-marker" aria-hidden="true"></i>
                                            <p>Alternatives, Inc.<br/>
                                               2120 3rd Ave North<br/>
                                               Billings, MT 59101
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="info">
                                            <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                            <p><a href="mailto:courtlink@altinc.net">courtlink@altinc.net</a></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="info">
                                            <i className="fa fa-phone" aria-hidden="true"></i>
                                            <p>(406) 256-3501</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            {this.props.auth.loggedIn && <div className="col-sm-12">
                                <p>For authorized users of CourtLink, we provide this staff contact list. Please do not give out direct extensions or Agency Cell Phone numbers to clients.</p>
                                <p><button className='link-button' onClick={() => this.downloadDocument()}>Beta Jail Alternatives and Misdemeanor Probation Contact List</button></p>

                            </div>}
                        </div>
                    </div>
                </div>
            </section>);
    }
    downloadDocument = () => {
        const CourtLinkDocumentIdRequest = {
            DocumentId: 1,
            UserId: -1
        };
        apiService.post("DOWNLOADDOCUMENT", CourtLinkDocumentIdRequest).then(response => {
            if (response.Success && response.path) {
                window.open(APP_URLS.DOWNLOAD_API_URL + response.path);
            }
            else {
                this.props.actions.showAlert({ message: "File not Found.", variant: 'error' });
            }
            this.setState({ loading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });
        });
    };
}
const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps)(Contact);