import React, { Component } from 'react';
class Resource extends Component {
    render() {
        return (<section id="resources" className="wow fadeIn">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="section-header">
                            <h3>Other Resources</h3>
                            <p></p>
                        </div>
                        <div className="resourceListing">
                            <ul>
                                {/* <li><a href="http://www.altcourtlink.net/Home/Download/1010" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i>Beta Alternatives Program Overview</a></li> */}
                                <li><a href="http://www.altinc.net" target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i>Alternatives, Inc. Public Website</a></li>
                                <li><a href="https://www.scramsystems.com/products/scram-gps/" target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i>About Active GPS</a></li>
                                <li><a href="http://www.pharmchem.com/default.asp" target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i>About PharmChem Drug Patch</a></li>
                                <li><a href="http://www.scramsystems.com/index/scram/continuous-alcohol-monitoring" target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i>About SCRAM</a></li>
                                <li><a href="http://www.scramsystems.com/index/scram/scram-remote-breath" target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i>About Remote Breathalyzers</a></li>
                                <li><a href="https://youtu.be/KYHRvhE_hqE" target="_blank" rel="noopener noreferrer"><i className="fa fa-file-video-o" aria-hidden="true"></i>Video: How SCRAM Works</a></li>
                                <li><a href="https://youtu.be/4uWESjwTF24" target="_blank" rel="noopener noreferrer"><i className="fa fa-file-video-o" aria-hidden="true"></i>Video: SCRAM Compliance, Accountability and Assessment</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
    }
}

export default Resource;