import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { apiService } from '../../../services/api.service';
import MaterialTable from "material-table";
import * as actions from '../../../store/actions';
import { bindActionCreators } from 'redux';

class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersList: [],
            isLoading: true
        }
    }
    getusers = () => {
        apiService.post('GETUSERS', { UserId: -1 }).then(response => {
            if (response.Success && response.CourtLinkUsers) {
                const { auth } = this.props;
                var users = response.CourtLinkUsers.filter(function (user) {
                    return user.UserId !== auth.user.UserId
                });
                this.setState({ usersList: users });

            }
            this.setState({ isLoading: false });
        }, error => {
            this.setState({ isLoading: false });
            this.props.actions.showAlert({ message: error, variant: 'error' });

        });
    }

    componentDidMount() {
        this.getusers();
    }
    render() {

        const { usersList, isLoading } = this.state;
        return (

            <Fragment>
                <section className="innerPage">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <MaterialTable
                                    columns={[
                                        { title: "User Name", field: "UserName" },
                                        {
                                            title: "First Name", field: "FirstName"
                                        },
                                        {
                                            title: "Last Name", field: "LastName"
                                        },
                                        {
                                            title: "Agency",
                                            field: "CourtAgency"
                                        },
                                        {
                                            title: "Roles",
                                            field: "Roles",
                                            render: rowData => rowData.Roles !== null ? rowData.Roles.split('<br>').map(role => {
                                                return <span key={role}>{role}<br /></span>
                                            }) : ""
                                        },
                                        {
                                            title: "Active",
                                            field: "Active"
                                        }
                                    ]}
                                    options={{
                                        pageSize: 25,
                                        pageSizeOptions: [5, 10, 25, 50, 100],
                                        toolbar: true,
                                        paging: true,
                                        paginationType: "normal",
                                        exportButton: true,
                                        exportAllData:true,
                                        view: true,
                                        headerStyle: {
                                            backgroundColor: '#C6C5B9',
                                            color: '#FFF'
                                        },
                                        actionsColumnIndex: -1,
                                        minBodyHeight:"325px",
                                        columnsButton:true
                                    }}
                                    isLoading={isLoading}
                                    data={usersList}
                                    localization={{
                                        pagination: {
                                            labelRowsSelect: "rows per page"
                                        }
                                       
                                    }}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Edit User',
                                            onClick: (event, rowData) => this.props.history.push(`/admin/user/edit/${rowData.UserId}`)
                                        },
                                        {
                                            icon: 'vpn_key',
                                            tooltip: 'Reset Password',
                                            onClick: (event, rowData) => this.props.history.push(`/admin/user/resetpassword/${rowData.UserId}`)
                                        },
                                        {
                                            icon: () => <i className="fa fa-user-plus" aria-hidden="true"></i>,
                                            tooltip: 'Add User',
                                            isFreeAction: true,
                                            onClick: (event, rowData) => this.props.history.push(`/admin/user/adduser`)
                                        }
                                    ]}
                                    title="Users"
                                    
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);