import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';

import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../../../shared/components/ui/form/text-validator';
import { apiService } from '../../../services/api.service';
import Alert from 'react-bootstrap/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        let { match, auth } = this.props;

        const userId = match.params.id;
        if (userId) {
            apiService.post('GETUSERS', { UserId: userId }).then(response => {
                let userdetails = response.CourtLinkUsers;
                this.setState({ user: userdetails[0] });
            });
        }
        this.state = {
            resetpassword: {
                userId: userId,
                Action: "ResetPassword",
                actionperformedby: auth.user.UserName
            },
            user: {
                UserName: '',
                Password: '',
                FirstName: '',
                RoleIds: "",
                Active: 'Y',
                Email: '',
                LastName: '',
                CourtAgency: '',
                Phone: '',
                Address: ''
            },
            message: "",
            messageType: "",
            loading: false
        }
    }
    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.resetpassword.NewPassword) {
                return false;
            }
            return true;
        });
    }
    handleCancel = () => {

        this.props.history.push('/admin/user/users');
    }
    handleSubmit = (e) => {

        e.preventDefault();
        const { resetpassword } = this.state;
        let data = {
            userId: resetpassword.userId,
            Action: resetpassword.Action,
            ActionPerformedBy: resetpassword.actionperformedby,
            NewPassword: resetpassword.NewPassword

        }
        this.setState({ loading: true });
        apiService.post('RESETPASSWORD', data).then(response => {
            if (response.Success) {
                this.setState({ loading: false });
                this.props.actions.showAlert({ message: response.Message, variant: 'success' });
                this.props.history.push('/admin/user/users');
            }
            else {
                this.setState({ loading: false, message: response.Message, messageType: "danger" });
            }
        },
            error => this.setState(prevState => {
                this.setState({ loading: false, message: error, messageType: "danger" });
            })
        );

    }
    handleChange = (e) => {

        const { resetpassword } = this.state;
        resetpassword[e.target.name] = e.target.value;
        this.setState({ resetpassword });
    }
    hadleCloseAlert = (e) => {
        this.setState({ message: "", messageType: "" });
    }
    render() {
        const { resetpassword, user, message, messageType, loading } = this.state
        return (
            <Fragment>
                <section className="innerPage">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                                <div className="formsDiv">
                                    <h1>
                                        Reset Password
                                    </h1>
                                    <p>Enter a new password for {user.FirstName} {user.LastName} ({user.CourtAgency}):</p>
                                    {
                                        message &&
                                        <Alert variant={messageType} onClose={() => this.hadleCloseAlert(false)} dismissible>{message}</Alert>
                                    }

                                    <ValidatorForm
                                        onSubmit={this.handleSubmit}
                                        className="form-horizontal">
                                        <div className="form-group1">
                                            <div className="row">
                                                <label className="col-sm-12 control-label">New Password</label>
                                                <div className="col-sm-12">
                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="NewPassword"
                                                        type="password"
                                                        validators={['required', 'minStringLength:6']}
                                                        errorMessages={['New Password is Required.', 'Please enter at least 6 characters.']}
                                                        value={resetpassword.NewPassword}
                                                        className="form-control input-sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group1">
                                            <div className="row">
                                                <label className="col-sm-12 control-label">Confirm Password</label>
                                                <div className="col-sm-12">
                                                    <TextValidator
                                                        onChange={this.handleChange}
                                                        name="ConfirmPassword"
                                                        type="password"
                                                        validators={['required', 'isPasswordMatch']}
                                                        errorMessages={['This field is Required.', 'Password Mismatch.']}
                                                        value={resetpassword.ConfirmPassword}
                                                        className="form-control input-sm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group1"> <div className="row">
                                            <div className="col-sm-12">
                                                <button className="btn btn-primary" disabled={loading} > Update</button>
                                                <button className="btn btn-primary" type="button" onClick={this.handleCancel}>Cancel</button>
                                            </div>
                                        </div>
                                        </div>
                                    </ValidatorForm>
                                    {loading && <div className="loaderDiv"><div className="loader"><CircularProgress /></div></div>}
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        alert: state.alert
    }
}
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch
            )
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
