import React, { Component } from 'react';
import MaterialTable from "material-table";
import { apiService } from '../../services/api.service';
import { COMPANY_ID } from '../../config/api.config';

import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';

class TreatmentGroupTab extends Component {


    state = {
        datalist: [],
        isLoading : true
    };

    componentDidMount() {
            let clientData = { ClientSessionNoteId: -1, ClientProgramEnrollmentId: this.props.clientprogramenrollmentid, CompanyId: COMPANY_ID };
            apiService.post('GETCOURTLINKSESSIONNOTES', clientData).then(response => {

                if (response.Success && response.CourtLinkClientSessionNotes != null) {
                    let details = response.CourtLinkClientSessionNotes;
                    this.props.onTreatmentGroupsDataLoad(details);
                    this.setState({

                        datalist: details
                    });
                }
                this.setState({ isLoading: false });
            }, error => {
                this.setState({ isLoading: false });
                this.props.action.showAlert({ message: error, variant: 'error' });
            });
      
    }
    render() {
        const { datalist,isLoading } = this.state;
        return (

            <MaterialTable
                columns={[
                    { title: "Treatment Group", field: "TreatmentGroup" },
                    {
                        title: "Session Details", field: "TreatmentGroupDetail"
                    },
                    {
                        title: "Attendance", field: "Attendance",
                    },


                ]}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [5, 10, 25, 50, 100],
                    toolbar: true,
                    paging: true,
                    paginationType: "normal",
                    headerStyle: {
                        backgroundColor: '#C6C5B9',
                        color: '#FFF'
                    },
                    actionsColumnIndex: -1,
                    minBodyHeight:"325px",
                    columnsButton:true
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: "rows per page"
                    }

                }}
                isLoading = {isLoading}
                data={datalist}
                title="Treatment Group Data"
            />

        );
    }
}
const mapStateToProps = state => {
    return {
        alert: state.alert
    };
};
const mapDispatchToProps = dispatch => {
    return {
        actions: {
            showAlert: bindActionCreators(
                actions.showAlert,
                dispatch

            )
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(TreatmentGroupTab);

