import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

class TransferList extends Component {
    constructor(props) {

        super(props);
        this.state = {
            left: [],
            right: [],
            checked: [],
            leftChecked: [],
            rightChecked: [],
            classes: makeStyles(theme => ({
                root: {
                    margin: 'auto',
                },
                paper: {
                    width: 200,
                    height: 230,
                    overflow: 'auto',
                },
                button: {
                    margin: theme.spacing(0.5, 0),
                },
            }))
        }
    }
    componentDidMount() {
        const servicetitles = [];
        var transferlist = {
            allselected: []
        };
        for (const [index, value] of this.props.servicedata.entries()) {
            servicetitles.push(value.ServiceFormatted);
        }
        this.setState({
            left: servicetitles
        });
        this.props.ontransferlistselection(this.getselectedserviceid(transferlist));
    }
    not = (a, b) => {
        return a.filter(value => b.indexOf(value) === -1);
    }
    intersection = (a, b) => {
        return a.filter(value => b.indexOf(value) !== -1);
    }
    handleToggle = value => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checked: newChecked });
    };
    handleAllRight = () => {
      
        var transferlist = {
            allselected: this.state.right.concat(this.state.left)
        };
        this.setState({
            right: this.state.right.concat(this.state.left),
            left: []
        });
        this.props.ontransferlistselection(this.getselectedserviceid(transferlist));
    };
    handleCheckedRight = (leftChecked) => {
        var transferlist = {
            allselected: this.state.right.concat(leftChecked)
        };
        this.setState({
            right: this.state.right.concat(leftChecked),
            left: this.not(this.state.left, leftChecked),
            chcecked: this.not(this.state.checked, leftChecked)
        });
        this.props.ontransferlistselection(this.getselectedserviceid(transferlist));
    };
    handleCheckedLeft = (rightChecked) => {
        var transferlist = {
            allselected: this.not(this.state.right, rightChecked)
        };
        this.setState({
            left: this.state.left.concat(rightChecked),
            right: this.not(this.state.right, rightChecked),
            chcecked: this.not(this.state.checked, rightChecked)
        });

        this.props.ontransferlistselection(this.getselectedserviceid(transferlist));

    };

    handleAllLeft = () => {
        var transferlist = {
            allselected: []
        };
        this.setState({
            left: this.state.left.concat(this.state.right),
            right: [],
        });
      
        this.props.ontransferlistselection(this.getselectedserviceid(transferlist));
    };

    getselectedserviceid = (transferlist) => {
        var selectedserviceids = [];
        var count = 0;
        this.props.servicedata.map(function (item, key) {
            if (transferlist.allselected.indexOf(item.ServiceFormatted) !== -1) {
                selectedserviceids[count] = item.ClientProgramServiceId;
                count++;
            }
            return null;
        });
        return selectedserviceids;
    };


    customList = items => (

        <Paper className={this.state.classes.paper}>
            <List dense component="div" role="list">
                {items.map(value => {
                    const labelId = `transfer-list-item-${value}`;

                    return (

                        <ListItem key={value} role="listitem" button onClick={this.handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={this.state.checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    render() {
        const { classes } = this.props;

        const { checked, left, right } = this.state;

        const leftChecked = this.intersection(checked, left);
        const rightChecked = this.intersection(checked, right);
        return (

            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                <div className="labelCaption" style={{marginBottom:0}}>
                    <label><b> Available Services</b></label>   <label><b>Print These Services</b></label>
                </div>
                <Grid item>{this.customList(this.state.left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={this.handleAllRight}
                            disabled={this.state.left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={() => this.handleCheckedRight(leftChecked)}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                             onClick={() => this.handleCheckedLeft(rightChecked)}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={this.handleAllLeft}
                            disabled={this.state.right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                </Button>
                    </Grid>
                </Grid>
             
                <Grid item>{this.customList(this.state.right)}</Grid>
            </Grid>

        );
    }
}

export default withStyles(theme => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}))(TransferList);
