import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { apiService } from "../../services/api.service";
import ServiceEquipmentTab from "../../containers/clientdata/service_equipmenttab";
import ServiceplacementTab from "../../containers/clientdata/services_placementtab";
import ServiceViolationTab from "../../containers/clientdata/services_violationtab";
import ServiceServicePeriodTab from "../../containers/clientdata/services_serviceperiodtab";
import { COMPANY_ID } from '../../config/api.config';
import FormatDateTime from "../../shared/components/functional/DateTimeFormatter";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}

        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

class VerticalServicesTab extends Component {
    constructor(props) {

        super(props);
        this.state = {
            value: 0,
            servicedata: [],
            servicelefttabdata: [],
            clientprogramserviceid: 0,
            selectedtabvalue: 0,
            horizontaltabvalue: 0
        }
        this.ServiceLeftTabDataRequest();
    }
    ServiceLeftTabDataRequest = () => {
        let servicelefttabrequest = { ClientProgramEnrollmentId: this.props.clientprogramenrollmentid, ClientProgramServiceId: -1, CompanyId: COMPANY_ID };
        apiService.post('GETCLIENTPROGRAMSERVICELEFTTABS', servicelefttabrequest).then(response => {
            if (response.Success && response.ClientProgramServiceLeftTabs != null) {
                let details = response.ClientProgramServiceLeftTabs;
                this.props.onDataload(details);
                this.setState({
                    servicelefttabdata: details,
                    clientprogramserviceid: details[0].ClientProgramServiceId
                });
                this.fetchselectedservice(this.state.clientprogramserviceid, 0);
            }
        }, error => {
            //    this.props.onShowAlert({ message:error, variant: 'error' });
        });
    };
    handleChange = (e, newValue) => {
        const { clientprogramserviceid } = e.currentTarget.attributes;
        const programserviceid = clientprogramserviceid.value;
        if (programserviceid > 0) {
            this.fetchselectedservice(programserviceid, newValue);
        }
    };
    fetchselectedservice = (programserviceid, newValue) => {
        let servicedatarequest = { ClientProgramEnrollmentId: this.props.clientprogramenrollmentid, ClientProgramServiceId: programserviceid, CompanyId: COMPANY_ID };
        apiService.post('GETCLIENTPROGRAMSERVICES', servicedatarequest).then(response => {

            if (response.Success) {
                let details = response.ClientProgramServices;
                this.setState({
                    value: newValue,
                    servicedata: details[0],
                    horizontaltabvalue: 0
                });
            }
        }, error => {
            //    this.props.onShowAlert({ message:error, variant: 'error' });
        });

    };
    horizontaltabhandleChange = (event, newValue) => {
        this.setState({ horizontaltabvalue: newValue })
    };
    render() {
        
        const { value, servicelefttabdata, servicedata, horizontaltabvalue } = this.state;
        let items = [];
        var count, counttabpanel = 0;
        for (const [index, value] of servicelefttabdata.entries()) {
            items.push(<Tab label={value.ServiceFormatted} clientprogramserviceid={value.ClientProgramServiceId} {...a11yProps(index)} key={index} />)
        }
        if (Object.keys(servicedata).length < 1) {
            return null;
        }
        else {
            return (
                <div className="clientServices card">
                    <div className={'mainverticalTab'}  >
                        <div className='card-header'>
                            <h3>Services</h3>
                        </div>
                        <div className='card-body'>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={this.handleChange}
                                aria-label="Vertical tabs example"
                                className={'serviceTab'}
                            >
                                {items}
                            </Tabs>
                            <TabPanel className='serviceChild' value={0} index={0} >
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group1">
                                                <label className=" control-label"><b>Service</b></label>
                                                <label className=" control-label">{servicedata.Service}</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group1">
                                                <label className=" control-label"><b>Start Date</b> </label>
                                                <label className=" control-label">
                                                    <FormatDateTime
                                                        date={servicedata.StartDate}
                                                        format="MM/DD/YY"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group1">
                                                <label className=" control-label"><b>End Date</b> </label>
                                                <label className=" control-label">
                                                    <FormatDateTime
                                                        date={servicedata.EndDate}
                                                        format="MM/DD/YY"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group1">
                                                <label className=" control-label"><b>Service Status</b> </label>
                                                <label className=" control-label">{servicedata.Staus}</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group1">
                                                <label className=" control-label"><b>Service Manager</b> </label>
                                                <label className=" control-label">{servicedata.ServiceManager}</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group1">
                                                <label className=" control-label"><b>Referral Source</b> </label>
                                                <label className=" control-label">{servicedata.ReferralSource}</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group1">
                                                <label className=" control-label"><b>Docket(s)</b> </label>
                                                <label className=" control-label">{servicedata.Dockets}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {(servicedata.TabDisplay_Violations === 'Y' || servicedata.TabDisplay_Equipment === 'Y' || servicedata.TabDisplay_ServicePeriods === 'Y' || servicedata.TabDisplay_Placements === 'Y') &&
                                        <div className="pt-5">
                                            <div className="verticalTabsGrid">
                                                <AppBar position="static">
                                                    <Tabs value={horizontaltabvalue} onChange={this.horizontaltabhandleChange} >
                                                        {servicedata.TabDisplay_Equipment === 'Y' &&
                                                            <Tab label="Equipment" {...a11yProps(count++)} />

                                                        }
                                                        {servicedata.TabDisplay_Placements === 'Y' &&
                                                            <Tab label="Placement" {...a11yProps(count++)} />

                                                        }
                                                        {servicedata.TabDisplay_ServicePeriods === 'Y' &&
                                                            <Tab label="Service Periods" {...a11yProps(count++)} />
                                                        }
                                                        {servicedata.TabDisplay_Violations === 'Y' &&
                                                            <Tab label="Violations" {...a11yProps(count++)} />
                                                        }

                                                    </Tabs>
                                                </AppBar>
                                                {servicedata.TabDisplay_Equipment === 'Y' &&
                                                    <TabPanel value={horizontaltabvalue} index={counttabpanel++}>
                                                        <ServiceEquipmentTab clientprogramserviceid={servicedata.ClientProgramServiceId}>
                                                        </ServiceEquipmentTab>
                                                    </TabPanel>
                                                }
                                                {servicedata.TabDisplay_Placements === 'Y' &&
                                                    < TabPanel value={horizontaltabvalue} index={counttabpanel++}>

                                                        <ServiceplacementTab clientprogramserviceid={servicedata.ClientProgramServiceId}>
                                                        </ServiceplacementTab>
                                                    </TabPanel>
                                                }
                                                {servicedata.TabDisplay_ServicePeriods === 'Y' &&
                                                    < TabPanel value={horizontaltabvalue} index={counttabpanel++}>
                                                        <ServiceServicePeriodTab clientprogramserviceid={servicedata.ClientProgramServiceId}>
                                                        </ServiceServicePeriodTab>
                                                    </TabPanel>
                                                }
                                                {servicedata.TabDisplay_Violations === 'Y' &&
                                                    <TabPanel value={horizontaltabvalue} index={counttabpanel++}>
                                                        <ServiceViolationTab clientprogramserviceid={servicedata.ClientProgramServiceId}>
                                                        </ServiceViolationTab>
                                                    </TabPanel>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default withStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 245,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}))(VerticalServicesTab);


