import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Custom components
import * as actions from '../../store/actions'
// API service
// Interal pages
import Login from './login';
import ForgotPassword from './forgot-password'
import ContactUsMap from './contact-us-map';
import About from './about';
import Resource from './resource';
import Contact from './contact';
import Features from './features';


class Home extends Component {
   state = {
      showForgetPasswordForm: false
   }

   componentDidMount() {
   }

   handleToggleForgotPasswordForm = (e) => {
      this.setState({ showForgetPasswordForm: !this.state.showForgetPasswordForm, user: {}, resetlink: {} });
      e.preventDefault();
   }

   handleRedirection = (url) => {
      this.props.history.push(url);
   }

   render() {
      const {  showForgetPasswordForm } = this.state;
      const { auth } = this.props;
      return (
         <Fragment>
            <div id="home">
               <section id="intro" className="clearfix">
                  <div className="container">
                     <div className="row">
                        <div className="col-sm-6">
                           <div className="intro-info">
                              <h2>Alternatives CourtLink</h2>
                              <p className="text-white">Alternatives CourtLink is a controlled-access system designed to facilitate information sharing between local court systems and referral sources and Alternatives. If you believe you should have access to this site, please click the button below to contact us with your request.</p>
                              <div>
                                 <a href="#contact" className="btn-get-started scrollto">Learn More</a>
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-5 offset-sm-1">
                           {
                              !auth.loggedIn && (
                                 <div className="card card-signin">
                                    <div className="card-body">
                                       {
                                          showForgetPasswordForm ?
                                             (
                                                <ForgotPassword onForgotPasswordForm={this.handleToggleForgotPasswordForm} />
                                             )
                                             :
                                             (
                                                <Login onForgotPasswordForm={this.handleToggleForgotPasswordForm} onRedirect={this.handleRedirection} />
                                             )
                                       }
                                    </div>
                                 </div>
                              )
                           }
                        </div>
                     </div>
                  </div>
               </section>
            </div>
            <About />
            <Features />
            <Resource />
            {/* <ScrollableAnchor id={'contact-us'} > */}
               <Contact />
            {/* </ScrollableAnchor> */}
            <ContactUsMap />
         </Fragment>
      );
   }
}
const mapStateToProps = state => {
   return {
      auth: state.auth
   }
}
const mapDispatchToProps = dispatch => {
   return {
      actions: {
         loginSuccess: bindActionCreators(
            actions.loginSuccess,
            dispatch
         )
      }
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);